


















































.section--order-online .order-online--content {
  .skip-the-line {
    line-height: 1 !important;

    @media only screen and (max-width: 414px) {
      font-size: 48px !important;
    }
  }
}
